import React from "react";

export default function renderIcon({
  width = 65.653,
  height = 65.653,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.374"
      height="38.408"
      viewBox="0 0 35.374 38.408"
    >
      <g
        id="Group_689"
        data-name="Group 689"
        transform="translate(-12.679 -11.776)"
      >
        <path
          id="Path_212"
          data-name="Path 212"
          d="M1154.972,2241.249a7.394,7.394,0,1,1,7.394-7.394,7.4,7.4,0,0,1-7.394,7.394m0-11.712a4.318,4.318,0,1,0,4.317,4.318,4.322,4.322,0,0,0-4.317-4.318"
          transform="translate(-1134.898 -2191.064)"
          fill="#fff"
        />
        <path
          id="Path_213"
          data-name="Path 213"
          d="M1180.684,2232.6a7.394,7.394,0,1,1,7.394-7.395,7.4,7.4,0,0,1-7.394,7.395m0-11.712a4.318,4.318,0,1,0,4.317,4.318,4.323,4.323,0,0,0-4.317-4.318"
          transform="translate(-1140.025 -2189.341)"
          fill="#fff"
        />
        <path
          id="Path_214"
          data-name="Path 214"
          d="M1164.951,2226.242h-3.077v-17.7a5.918,5.918,0,0,1,3.519-6.128c3.031-1.249,14.859-5.193,15.36-5.361l.3-.066a4.674,4.674,0,0,1,3.425.937,3.479,3.479,0,0,1,1.143,2.718v18.988h-3.077v-18.988a.639.639,0,0,0-.1-.409,1.54,1.54,0,0,0-.9-.2c-1.535.513-12.244,4.1-14.979,5.226a2.9,2.9,0,0,0-1.628,2.987l.014.2Z"
          transform="translate(-1137.744 -2185.182)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
