import apiService from "../apiService";

const apiPath = "/api/v1";

export const userService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  POST_LINE_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login/line`, params);
  },
  POST_CHECK_TOKEN: () => {
    return apiService.get(`${apiPath}/login/check_token`);
  },

  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPath}/customer/me`);
  },
  GET_MY_WALLET: () => {
    return apiService.get(`${apiPath}/wallet/me`);
  },

  PUT_EMAIL_UPDATE_DETAIL: (params) => {
    return apiService.put(`${apiPath}/customer/me/email`, params);
  },
  PUT_MEMBER_TYPE_UPDATE_DETAIL: (params) => {
    return apiService.put(`${apiPath}/customer/me/member-type`, params);
  },
  POST_REGISTER_CUSTOMER: (params) => {
    return apiService.post(`${apiPath}/customer/register`, params);
  },

  // ACTIVITY FORM
  GET_ACTIVITY_FORM_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/activity-form${queryString ? queryString : ""}`
    );
  },
  GET_ACTIVITY_FORM_DETAIL: (id) => {
    return apiService.get(`${apiPath}/activity-form/${id}`);
  },
  GET_ACTIVITY_FORM_PDPA_DETAIL: (id) => {
    return apiService.get(`${apiPath}/activity-form/${id}/pdpa`);
  },

  // ACTIVITY FORM HISTORY
  GET_MY_ACTIVITY_FORM_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/activity-form-history/me${queryString ? queryString : ""}`
    );
  },
  GET_MY_ACTIVITY_FORM_DETAIL: (id) => {
    return apiService.get(`${apiPath}/activity-form-history/${id}`);
  },
  GET_ACTIVITY_FORM_HISTORY_BY_ME_ACTIVITY: (id, memberType) => {
    return apiService.get(
      `${apiPath}/activity-form-history/me/activity/${id}?member_type=${memberType}`
    );
  },
  POST_ACTIVITY_FORM_HISTORY_REGISTER: (params) => {
    return apiService.post_formdata(
      `${apiPath}/activity-form-history/register`,
      params
    );
  },
  POST_ACTIVITY_FORM_HISTORY_CHECK_IN: (params) => {
    return apiService.post(
      `${apiPath}/activity-form-history/scan/admin`,
      params
    );
  },

  // ACTIVITY
  GET_ACTIVITY_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/activity${queryString ? queryString : ""}`
    );
  },
  GET_ACTIVITY_DETAIL: (id) => {
    return apiService.get(`${apiPath}/activity/${id}`);
  },

  // ACTIVITY HISTORY
  GET_MY_ACTIVITY: (queryString) => {
    return apiService.get(
      `${apiPath}/activity-history/me${queryString ? queryString : ""}`
    );
  },
  GET_ACTIVITY_HISTORY_BY_ME_ACTIVITY: (id, memberType) => {
    return apiService.get(
      `${apiPath}/activity-history/me/activity/${id}?member_type=${memberType}`
    );
  },
  POST_ACTIVITY_HISTORY_REGISTER: (params) => {
    return apiService.post_formdata(
      `${apiPath}/activity-history/register`,
      params
    );
  },
  POST_ACTIVITY_HISTORY_LIMIT_REGISTER: (params) => {
    return apiService.post_formdata(
      `${apiPath}/activity-history/register-limit`,
      params
    );
  },
  POST_ACTIVITY_HISTORY_SCAN: (params) => {
    return apiService.post(`${apiPath}/activity-history/scan/customer`, params);
  },
  POST_ACTIVITY_QUESTION_EDIT: (params) => {
    return apiService.post_formdata(
      `${apiPath}/activity-history/me/question`,
      params
    );
  },

  // REWARD
  GET_REWARD_LIST: (queryString) => {
    return apiService.get(`${apiPath}/reward${queryString ? queryString : ""}`);
  },
  GET_REWARD_DETAIL: (id) => {
    return apiService.get(`${apiPath}/reward/${id}`);
  },

  // REWARD HISTORY
  GET_MY_REWARD: () => {
    return apiService.get(`${apiPath}/reward-history/me`);
  },
  GET_REWARD_HISTORY_DETAIL: (id) => {
    return apiService.get(`${apiPath}/reward-history/${id}`);
  },
  POST_REWARD_REDEEM: (params) => {
    return apiService.post(`${apiPath}/reward-history/redeem`, params);
  },
  POST_SHIPPING: (params) => {
    return apiService.post(`${apiPath}/reward-history/shipping`, params);
  },
  PUT_USE_E_COUPON: (id) => {
    return apiService.put(`${apiPath}/reward-history/e-conpon/active/${id}`);
  },

  // NEW
  GET_NEW_LIST: (queryString) => {
    return apiService.get(`${apiPath}/new${queryString ? queryString : ""}`);
  },
  GET_NEW_DETAIL: (id) => {
    return apiService.get(`${apiPath}/new/${id}`);
  },

  // PRODUCT_WARRANTEE
  GET_MY_PRODUCT_WARRANTEE: (queryString) => {
    return apiService.get(
      `${apiPath}/product-warrantee/me${queryString ? queryString : ""}`
    );
  },
  GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN: (id) => {
    return apiService.get(`${apiPath}/product-warrantee/serial-no/${id}`);
  },
  POST_PRODUCT_WARRANTEE_REGISTER: (params) => {
    return apiService.post(`${apiPath}/product-warrantee/register`, params);
  },
  POST_PRODUCT_WARRANTEE_MEMBER_REGISTER: (params) => {
    return apiService.post(
      `${apiPath}/product-warrantee/register-member`,
      params
    );
  },
  POST_UPLOAD_SLIP: (params) => {
    return apiService.post_formdata(
      `${apiPath}/product-warrantee/upload/member`,
      params
    );
  },
  POST_NO_MEMBER_UPLOAD_SLIP: (params) => {
    return apiService.post_formdata(
      `${apiPath}/product-warrantee/upload`,
      params
    );
  },
  POST_UPLOAD_COPERATE_REGIS: (params) => {
    return apiService.post_formdata(
      `${apiPath}/product-warrantee/upload/coperate`,
      params
    );
  },

  // QUESTION
  GET_QUESTION_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/question${queryString ? queryString : ""}`
    );
  },
  GET_QUESTION_DETAIL: (id) => {
    return apiService.get(`${apiPath}/question/${id}`);
  },

  // ASSESSMENT
  GET_ASSESSMENT_BY_ME_QUESTION: (id, memberType) => {
    return apiService.get(
      `${apiPath}/assessment/me/question/${id}?member_type=${memberType}`
    );
  },
  POST_ASSESSMENT_DETAIL: (params) => {
    return apiService.post(`${apiPath}/assessment/question`, params);
  },

  // ADMIN
  POST_ADMIN_LOGIN: (params) => {
    return apiService.post(`${apiPath}/admin-login`, params);
  },
  POST_ADMIN_ACTIVITY_SCAN: (params) => {
    return apiService.post(`${apiPath}/activity-history/scan/admin`, params);
  },

  // BRANCH
  GET_BRANCH_LIST: (queryString) => {
    return apiService.get(`${apiPath}/branch${queryString ? queryString : ""}`);
  },

  // LUCKY DRAW
  GET_LUCKY_DRAW_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/luckydraw-reward${queryString ? queryString : ""}`
    );
  },
  POST_PLAY_LUCKY_DRAW: (params) => {
    return apiService.post(`${apiPath}/luckydraw-reward/playgame`, params);
  },

  // PDPA
  GET_PDPA_LATEST: () => {
    return apiService.get(`${apiPath}/pdpa/latest`);
  },
  GET_PDPA_ACCEPTED_HISTORY: (id) => {
    return apiService.get(`${apiPath}/pdpa-history/me/pdpa/${id}`);
  },

  POST_PDPA_ACCEPTED_HISTORY: (params) => {
    return apiService.post(`${apiPath}/pdpa-history/accept`, params);
  },
  POST_PDPA_ACCEPTED_NOT_LOGIN_HISTORY: (params) => {
    return apiService.post(`${apiPath}/pdpa-history/accept-not-login`, params);
  },

  // NOTIFICATION
  GET_NOTIFICATION_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/notification-history/me/${queryString ? queryString : ""}`
    );
  },
  GET_POPUP_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/popup-history/me/${queryString ? queryString : ""}`
    );
  },
  POST_NOTIFICATION_READ_ALL: () => {
    return apiService.post(`${apiPath}/notification-history/read-all`);
  },
  PUT_POPUP_READ_DETAIL: (id) => {
    return apiService.put(`${apiPath}/popup-history/read/${id}`);
  },

  // ORGANIZATION
  PUT_MY_ORGANIZE_UPDATE_DETAIL: (params) => {
    return apiService.put(`${apiPath}/organization/me/update`, params);
  },
  POST_ORGANIZATION_CREATE: (params) => {
    return apiService.post(`${apiPath}/organization/register`, params);
  },
  POST_ORGANIZATION_ONLY_CREATE: (params) => {
    return apiService.post(`${apiPath}/organization/register/member`, params);
  },

  GET_CUSTOMER_ACCOUNT_BY_PROFILE: (id) => {
    return apiService.get(`${apiPath}/customer/phone-number/${id}`);
  },

  POST_CUSTOMER_SYNC_LINE: (params) => {
    return apiService.post(`${apiPath}/customer/sync/line`, params);
  },

  // OTP
  POST_OTP_REQUEST: (params) => {
    return apiService.post(`${apiPath}/sms/request-otp`, params);
  },
  POST_OTP_VERIFY: (params) => {
    return apiService.post(`${apiPath}/sms/verify-otp`, params);
  },

  // BOOKING
  GET_MY_BOOKING_LIST: () => {
    return apiService.get(`${apiPath}/booking/customer/me/latest`);
  },
  POST_BOOKING_CREATE: (params) => {
    return apiService.post(`${apiPath}/booking/create`, params);
  },
  POST_BOOKING_CANCEL: (params) => {
    return apiService.post(`${apiPath}/booking/cancel`, params);
  },

  // CALENDAR
  GET_HOLIDATE_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/holiday${queryString ? queryString : ""}`
    );
  },
  GET_TIME_SLOT_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/time-slot${queryString ? queryString : ""}`
    );
  },

  // SHOW ROOM
  GET_SHOWROOM_BOOKING_BY_CUS_BOOKING_ID: (cusID, bookingID) => {
    return apiService.get(
      `${apiPath}/showroom-visit/customer/${cusID}/booking/${bookingID}`
    );
  },
  POST_SHOWROOM_SCAN_QR_BY_ADMIN: (params) => {
    return apiService.post(`${apiPath}/showroom-visit/scan-qr/admin`, params);
  },
  POST_SHOWROOM_SCAN_QR_BY_CUSTOMER: (params) => {
    return apiService.post(
      `${apiPath}/showroom-visit/scan-qr/customer`,
      params
    );
  },
};
