export default function Hamburger({ isActive }) {
  return !isActive ? (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_286_5073)">
        <path
          d="M5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V5.5C3 4.95 3.19583 4.47917 3.5875 4.0875C3.97917 3.69583 4.45 3.5 5 3.5H19C19.55 3.5 20.0208 3.69583 20.4125 4.0875C20.8042 4.47917 21 4.95 21 5.5V19.5C21 20.05 20.8042 20.5208 20.4125 20.9125C20.0208 21.3042 19.55 21.5 19 21.5H5ZM5 19.5H19V5.5H5V19.5Z"
          fill="#4B1E78"
        />
      </g>
    </svg>
  ) : (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_286_4829)">
        <path
          d="M10.6 13.9L8.45 11.75C8.26667 11.5667 8.03333 11.475 7.75 11.475C7.46667 11.475 7.23333 11.5667 7.05 11.75C6.86667 11.9333 6.775 12.1667 6.775 12.45C6.775 12.7333 6.86667 12.9667 7.05 13.15L9.9 16C10.1 16.2 10.3333 16.3 10.6 16.3C10.8667 16.3 11.1 16.2 11.3 16L16.95 10.35C17.1333 10.1667 17.225 9.93333 17.225 9.65C17.225 9.36667 17.1333 9.13333 16.95 8.95C16.7667 8.76667 16.5333 8.675 16.25 8.675C15.9667 8.675 15.7333 8.76667 15.55 8.95L10.6 13.9ZM5 21.5C4.45 21.5 3.97917 21.3042 3.5875 20.9125C3.19583 20.5208 3 20.05 3 19.5V5.5C3 4.95 3.19583 4.47917 3.5875 4.0875C3.97917 3.69583 4.45 3.5 5 3.5H19C19.55 3.5 20.0208 3.69583 20.4125 4.0875C20.8042 4.47917 21 4.95 21 5.5V19.5C21 20.05 20.8042 20.5208 20.4125 20.9125C20.0208 21.3042 19.55 21.5 19 21.5H5ZM5 19.5H19V5.5H5V19.5Z"
          fill="#4B1E78"
        />
      </g>
    </svg>
  );
}
