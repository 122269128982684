import React from "react";
import { HomeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { MyPointWidget } from "widgets";
import { Buttons, Cards, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class HomeContainer extends React.Component {
  handleClickMenu = (e) => {
    this.props.router.navigate(e.path);
  };

  render() {
    const { data } = this.props;
    return (
      <HomeContainerStyled>
        <Headers.Logo mbNode="55px" nodeRight={<MyPointWidget />} />
        {data &&
          data.map((e, i) => (
            <div
              key={i}
              className="menu_item_wrap"
              onClick={() => this.handleClickMenu(e)}
            >
              <Cards.NavigationMenuItem
                isSurvey
                icon={e.icon}
                topLabel={e.name}
                bottomLabel={e.name_th}
              />
            </div>
          ))}
        {/* <div>
          <Buttons.BgStandard
            theme_cancel
            label={"Booking"}
            onClick={() => this.props.router.navigate(ROUTE_PATH.BOOKING)}
          />
        </div> */}
      </HomeContainerStyled>
    );
  }
}

export default withRouter(HomeContainer);
