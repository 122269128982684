import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BookingInfoConfirmStyled } from "./styled";
import moment from "moment";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { Buttons } from "components";

const BookingInfoConfirm = ({
  theme_standard,
  customer_first_name,
  customer_gender,
  customer_phone_number,
  date,
  product_th,
  start_time,
  end_time,
  onCancel,
  onConfirm,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BookingInfoConfirmStyled>
      <div className={customClass}>
        <div className="activity_confirm_container">
          <div className="avc_title">ยืนยันการสแกน</div>
          <div className="avc_body">
            <div className="avc_row">
              <div className="cr_left_col">ชื่อสินค้า</div>
              <div className="cr_right_col">{product_th}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">วันที่จอง</div>
              <div className="cr_right_col">
                {moment(date).format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">เวลาจอง</div>
              <div className="cr_right_col">
                {start_time}-{end_time}
              </div>
            </div>

            <div className="avc_row">
              <div className="cr_left_col">ชื่อลูกค้า</div>
              <div className="cr_right_col">{customer_first_name}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">เพศลูกค้า</div>
              <div className="cr_right_col">
                {RENDER_GENDER_TYPE(customer_gender)?.labelTh}
              </div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">เบอร์โทรลูกค้า</div>
              <div className="cr_right_col">{customer_phone_number}</div>
            </div>
          </div>
          <div className="avc_action_row">
            <div className="ca_col">
              <Buttons.BgStandard
                theme_cancel
                label={"ยกเลิก"}
                onClick={onCancel}
              />
            </div>
            <div className="ca_col">
              <Buttons.BgStandard label={"ตกลง"} onClick={onConfirm} />
            </div>
          </div>
        </div>
      </div>
    </BookingInfoConfirmStyled>
  );
};

BookingInfoConfirm.propTypes = {};

export default BookingInfoConfirm;
