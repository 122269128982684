import styled from "styled-components";

export const CelendarWidgetStyled = styled.div`
  .cd_header_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .top_left {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    }
    .action_next_prev_block {
      display: flex;
      column-gap: 14px;
      .top_right {
        cursor: pointer;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }
  .cd_body {
    display: flex;
    column-gap: 5px;
    .table_left_column {
      flex-basis: 50px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      .time_cell {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 4px);
        height: 30px;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        background: #b783eb;
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        white-space: pre-wrap;
        line-height: 14px;
        &.f_16 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        }
      }
    }
    .table_right_column {
      flex: 1;
      .day_row {
        margin-bottom: 5px;
        display: flex;
        column-gap: 5px;
        .day_cell {
          padding: 2px;
          width: calc(13.28% - 5.5px);
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: ${({ theme }) => theme.COLORS.PURPLE_1};
          text-align: center;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S11};
          white-space: pre-wrap;
          line-height: 14px;
        }
      }
      .data_cell_block {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 5px;
        .data_cell {
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(13.28% - 5.5px);
          height: 30px;
          background: #707070;
          color: #ffffff;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .bottom {
        display: flex;
        margin-bottom: 30px;
        .status_detail {
          height: 15px;
          display: flex;
          margin-right: 20px;
          align-items: center;
          .box {
            width: 26px;
            height: 15px;
            margin-right: 10px;
            &.full {
              background: #ff9c96;
            }
            &.almost {
              background: #efc788;
            }
            &.available {
              background: #a694b8;
            }
          }
          .status_title {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          }
        }
      }
    }
  }
`;
