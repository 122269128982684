import React from "react";
import { ProductWarranteeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { AlertControl } from "components/Modals/Alert";
import { MemberSwitchTypeWidget, MyPointWidget } from "widgets";
import { Displays, Forms, Headers, Icons } from "components";

class ProductWarranteeContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = () => {
    // this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}/id`);
  };

  handleSuccessScan = async (sn) => {
    const split = sn.split("/");
    const serialNo = split[split.length - 1];
    switch (serialNo) {
      case "showroom":
        this.handleShowRoomScan();
        break;
      default:
        this.setState({
          isBgLoading: true,
        });
        let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
          serialNo
        );
        if (res && res.status === 200) {
          this.setState({
            isBgLoading: false,
          });
          this.props.router.navigate(
            `${ROUTE_PATH.PRODUCT_WARRANTEE}/${serialNo}`
          );
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("เลขซีเรียลไม่ถูกต้อง");
        }
        break;
    }
  };

  handleShowRoomScan = async () => {
    AlertControl.show({
      title: "ยืนยันการเข้าโชว์รูม",
      description: "คุณต้องการเชื่อมข้อมูลการเข้าใช้โขว์รูม ใช่หรือไม่",
      btnLabel1: "กลับ",
      btnLabel2: "ตกลง",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await userService.POST_SHOWROOM_SCAN_QR_BY_CUSTOMER();
        if (res && res.status === 200) {
          this.setState({
            isBgLoading: false,
          });
          toast.success("เชื่อมข้อมูลการเข้าใช้โชว์รูมสำเร็จ");
          this.props.router.navigate(ROUTE_PATH.HOME);
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error(
            "เชื่อมข้อมูลไม่สำเร็จ เนื่องจากไอดีของท่านถูกแบนหรือวันนี้มีการเชื่อมข้อมูลแล้ว กรุณาติดต่อเจ้าหน้าที่"
          );
        }
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <ProductWarranteeContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.SubMenuProductWarrantee color="#fff" />}
          topLabel={"Product Warranty"}
          bottomLabel={"รับประกันสินค้า"}
        />
        <MemberSwitchTypeWidget type="2" />
        <Forms.ScanQRCodeForm
          onSuccessScan={this.handleSuccessScan}
          onSubmit={this.handleSubmit}
        />
        <div className="label_reccoment">
          {t("Scan the QR Code on the product box", { ns: "common" })}
        </div>
      </ProductWarranteeContainerStyled>
    );
  }
}

export default withTranslation(["profile"])(
  withRouter(ProductWarranteeContainer)
);
