import React from "react";
import { BookingContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";
import { MyPointWidget } from "widgets";
import { Buttons, Displays, Headers, Icons } from "components";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";

class BookingContainer extends React.Component {
  state = {
    isLoading: true,
    currentCase: 1,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_MY_BOOKING_LIST(
      `?date_type=booking_date&start_date=${moment().format(
        "YYYY-MM-DD"
      )}&end_date=${moment().add(14).format("YYYY-MM-DD")}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleNextCase = () => {
    this.setState((prevState) => ({
      currentCase: prevState.currentCase === 4 ? 1 : prevState.currentCase + 1, // Loop back to 1 after case 4
    }));
  };

  handleCancelBooking = async () => {
    AlertControl.show({
      title: "ยืนยันการยกเลิก",
      description: "คุณต้องการที่จะยกเลิกการจอง ใช่หรือไม่",
      btnLabel1: "กลับ",
      btnLabel2: "ตกลง",
      onEvent2: async () => {
        const { data } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let params = {
          booking_id: data._id,
        };
        let res = await userService.POST_BOOKING_CANCEL(params);
        if (res && res.status === 200) {
          this.fetchData();
          toast.success("ยกเลิกการจองสำเร็จ");
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ยกเลิกการจองไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่");
        }
      },
    });
  };

  renderContent = (e) => {
    const { data } = this.state;
    const { i18n } = this.props;
    switch (e.status) {
      case "1":
        return (
          <>
            <div className="status_detail">
              <div className="title">รายละเอียดการจองเวลา</div>
              <div className="detail_status">
                <div className="status yellow">รอการยืนยัน</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">กลุ่มเครื่องดนตรี</div>
                  <div className="title_detail">เครื่อง</div>
                  <div className="title_detail">รุ่น (ถ้ามี)</div>
                  <div className="title_detail">วันและเวลา</div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    เวลา {e.start_time} - {e.end_time} น.
                  </div>
                </div>
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
          </>
        );
      case "2":
        return (
          <>
            <div className="status_detail">
              <div className="title">รายละเอียดการจองเวลา</div>
              <div className="detail_status">
                <div className="status green">ยืนยันการจอง</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">กลุ่มเครื่องดนตรี</div>
                  <div className="title_detail">เครื่อง</div>
                  <div className="title_detail">รุ่น (ถ้ามี)</div>
                  <div className="title_detail">วันและเวลา</div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    เวลา {e.start_time} - {e.end_time} น.
                  </div>
                </div>
              </div>
              <div className="remark">
                *ในกรณีที่ท่านไม่สามารถมาตามเวลาได้ กรุณายกเลิกการจอง
                เพิ่อรักษาสิทธิ์ของท่านในการจองครั้งต่อไป
              </div>
              <div className="qr_sec">
                {/* <img src="/assets/images/mockups/image-qr.png" alt="qr" /> */}
                <QRCode
                  value={`showroom,${data.customer_ref_id},${data._id}`}
                  size={190}
                />
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
            {/* <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_cancel
                  label={"ไปหน้ายังไม่ได้ลงทำเบียน"}
                  onClick={this.handleNextCase} // Change back to Case 1 when clicked
                />
              </div> */}
          </>
        );
      case "3":
        return (
          <>
            <div className="status_detail">
              <div className="title">รายละเอียดการจองเวลา</div>
              <div className="detail_status">
                <div className="status orange">การจองไม่สำเร็จ</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">กลุ่มเครื่องดนตรี</div>
                  <div className="title_detail">เครื่อง</div>
                  <div className="title_detail">รุ่น (ถ้ามี)</div>
                  <div className="title_detail">วันและเวลา</div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    เวลา {e.start_time} - {e.end_time} น.
                  </div>
                </div>
              </div>
              <div className="remark">*{e.reason_reject}</div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ลงทะเบียนจองใหม่"}
                  onClick={() => {
                    this.setState({
                      data: false
                    });
                  }}
                />
              </div>
            </div>
            {/* <div className="btn_wrap">
              <Buttons.BgStandard
                className="btn"
                theme_cancel
                label={"ไปหน้าลงทะเบียนแล้ว (Case 3)"}
                onClick={this.handleNextCase}
              />
            </div> */}
          </>
        );

      default:
        break;
    }
    // switch (currentCase) {
    //   case 1:
    //     return (
    //       <>
    //         <div className="btn_wrap">
    //           <Buttons.BgStandard
    //             className="btn"
    //             theme_standard
    //             label={"ลงทะเบียนจอง"}
    //             onClick={() =>
    //               this.props.router.navigate(ROUTE_PATH.BOOKING_DETAIL)
    //             }
    //           />
    //         </div>
    //         <div className="btn_wrap">
    //           <Buttons.BgStandard
    //             className="btn"
    //             theme_cancel
    //             label={"ไปหน้าลงทะเบียนแล้ว (Case 1)"}
    //             onClick={this.handleNextCase}
    //           />
    //         </div>
    //       </>
    //     );
    //   case 2:
    //     return (
    //       <>
    //         <div className="status_detail">
    //           <div className="title">รายละเอียดการจองเวลา</div>
    //           <div className="detail_status">
    //             <div className="status yellow">รอการยืนยัน</div>
    //           </div>
    //           <div className="detail">
    //             <div className="detail_left">
    //               <div className="title_detail">กลุ่มเครื่องดนตรี</div>
    //               <div className="title_detail">เครื่อง</div>
    //               <div className="title_detail">รุ่น (ถ้ามี)</div>
    //               <div className="title_detail">วันและเวลา</div>
    //             </div>
    //             <div className="detail_right">
    //               <div className="sub_title">Woodwind/Brass</div>
    //               <div className="sub_title">Flute</div>
    //               <div className="sub_title">-</div>
    //               <div className="sub_title">วันจันทร์ 15 ตุลาคม 2567</div>
    //               <div className="sub_title mb_20">เวลา 11.00 - 12.00 น.</div>
    //             </div>
    //           </div>
    //           <div className="btn_wrap">
    //             <Buttons.BgStandard
    //               className="btn"
    //               theme_standard
    //               label={"ยกเลิกการจอง"}
    //               onClick={this.handleNextCase}
    //             />
    //           </div>
    //         </div>
    //         <div className="btn_wrap">
    //           <Buttons.BgStandard
    //             className="btn"
    //             theme_cancel
    //             label={"ไปหน้าลงทะเบียนแล้ว (Case 2)"}
    //             onClick={this.handleNextCase}
    //           />
    //         </div>
    //       </>
    //     );
    //   case 3:
    //     return (
    //       <>
    //         <div className="status_detail">
    //           <div className="title">รายละเอียดการจองเวลา</div>
    //           <div className="detail_status">
    //             <div className="status orange">การจองไม่สำเร็จ</div>
    //           </div>
    //           <div className="detail">
    //             <div className="detail_left">
    //               <div className="title_detail">กลุ่มเครื่องดนตรี</div>
    //               <div className="title_detail">เครื่อง</div>
    //               <div className="title_detail">รุ่น (ถ้ามี)</div>
    //               <div className="title_detail">วันและเวลา</div>
    //             </div>
    //             <div className="detail_right">
    //               <div className="sub_title">Woodwind/Brass</div>
    //               <div className="sub_title">Flute</div>
    //               <div className="sub_title">-</div>
    //               <div className="sub_title">วันจันทร์ 15 ตุลาคม 2567</div>
    //               <div className="sub_title mb_20">เวลา 11.00 - 12.00 น.</div>
    //             </div>
    //           </div>
    //           <div className="remark">*Option เหตุผลจาก admin</div>
    //           <div className="btn_wrap">
    //             <Buttons.BgStandard
    //               className="btn"
    //               theme_standard
    //               label={"ลงทะเบียนจองใหม่"}
    //               onClick={this.handleNextCase}
    //             />
    //           </div>
    //         </div>
    //         <div className="btn_wrap">
    //           <Buttons.BgStandard
    //             className="btn"
    //             theme_cancel
    //             label={"ไปหน้าลงทะเบียนแล้ว (Case 3)"}
    //             onClick={this.handleNextCase}
    //           />
    //         </div>
    //       </>
    //     );
    //   case 4:
    //     return (
    //       <>
    //         <div className="status_detail">
    //           <div className="title">รายละเอียดการจองเวลา</div>
    //           <div className="detail_status">
    //             <div className="status green">ยืนยันการจอง</div>
    //           </div>
    //           <div className="detail">
    //             <div className="detail_left">
    //               <div className="title_detail">กลุ่มเครื่องดนตรี</div>
    //               <div className="title_detail">เครื่อง</div>
    //               <div className="title_detail">รุ่น (ถ้ามี)</div>
    //               <div className="title_detail">วันและเวลา</div>
    //             </div>
    //             <div className="detail_right">
    //               <div className="sub_title">Woodwind/Brass</div>
    //               <div className="sub_title">Flute</div>
    //               <div className="sub_title">-</div>
    //               <div className="sub_title">วันจันทร์ 15 ตุลาคม 2567</div>
    //               <div className="sub_title mb_20">เวลา 11.00 - 12.00 น.</div>
    //             </div>
    //           </div>
    //           <div className="remark">
    //             *ในกรณีที่ท่านไม่สามารถมาตามเวลาได้ กรุณายกเลิกการจอง
    //             เพิ่อรักษาสิทธิ์ของท่านในการจองครั้งต่อไป
    //           </div>
    //           <div className="qr_sec">
    //             <img src="/assets/images/mockups/image-qr.png" alt="qr" />
    //           </div>
    //           <div className="btn_wrap">
    //             <Buttons.BgStandard
    //               className="btn"
    //               theme_standard
    //               label={"ยกเลิกการจอง"}
    //               onClick={this.handleNextCase}
    //             />
    //           </div>
    //         </div>
    //         <div className="btn_wrap">
    //           <Buttons.BgStandard
    //             className="btn"
    //             theme_cancel
    //             label={"ไปหน้ายังไม่ได้ลงทำเบียน"}
    //             onClick={this.handleNextCase} // Change back to Case 1 when clicked
    //           />
    //         </div>
    //       </>
    //     );
    //   default:
    //     return null;
    // }
  };

  render() {
    const { isBgLoading, isLoading, data } = this.state;
    return (
      <BookingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.MusicNote color="#fff" />}
          topLabel={"Musical Instument Test Booking"}
          bottomLabel={"การจองทดสอบเครื่องดนตรี"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="section">
            <div className="activity_wrap">
              <img
                className="act_img"
                alt="activity"
                src={"/assets/images/mockups/new-1.jpg"}
              />
            </div>
            <div className="status_wrap">
              <div className="title">จองทดสอบเครื่องดนตรี</div>
              <div className="detail">
                Text text text text text sample text sample text sample text
                sample text sample text sample text sample text sample text
                sample text sample text sample text sample
              </div>
              <div className="annotation">
                <div>มีรายละเอียด กฎกติกา</div>
                <div>การตัดสินใจของ Admin ถือเป็นสิ้นสุด</div>
              </div>
            </div>
            {!data || data.status === "4" ? (
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ลงทะเบียนจอง"}
                  onClick={() =>
                    this.props.router.navigate(ROUTE_PATH.BOOKING_DETAIL)
                  }
                />
              </div>
            ) : (
              this.renderContent(data)
            )}
          </div>
        )}
      </BookingContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(BookingContainer)));
