import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  .checkbox_container {
    display: flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
    .checkbox_label {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      .cb_link {
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
  }
  .errors {
    margin-top: 2px;
    padding-left: 16px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  a {
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
`;
