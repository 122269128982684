export const BOOKING_PURPOSE_OPTIONS = [
  {
    labelEn: "Personal Use",
    labelTh: "ใช้เอง",
    value: "1",
  },
  {
    labelEn: "For Children",
    labelTh: "เลือกซื้อให้ลูกหลาน",
    value: "2",
  },
  {
    labelEn: "For Student",
    labelTh: "เลือกซื้อให้นักเรียน",
    value: "3",
  },
];

export const RENDER_BOOKING_PURPOSE = (key) => {
  switch (key) {
    case "1":
      return {
        labelEn: "Personal Use",
        labelTh: "ใช้เอง",
      };
    case "2":
      return {
        labelEn: "For Children",
        labelTh: "เลือกซื้อให้ลูกหลาน",
      };
    case "3":
      return {
        labelEn: "For Student",
        labelTh: "เลือกซื้อให้นักเรียน",
      };
    default:
      break;
  }
};
