import { ROUTE_PATH } from "utils/constants/routePath";
import { Icons } from "components";
import HomeContainer from "containers/HomeContainer";
import ActivitiesContainer from "containers/ActivitiesSystem/ActivitiesContainer";
import ActivitiesDetailContainer from "containers/ActivitiesSystem/ActivitiesDetailContainer";
import ActivitiesFormDetailContainer from "containers/ActivitiesSystem/ActivitiesFormDetailContainer";
import ActivityFormQuizContainer from "containers/ActivitiesSystem/ActivityFormQuizContainer";
import ProductWarranteeContainer from "containers/ProductWarranteeSystem/ProductWarranteeContainer";
import ProductWarranteeDetailContainer from "containers/ProductWarranteeSystem/ProductWarranteeDetailContainer";
import ProductWarranteeSuccessContainer from "containers/ProductWarranteeSystem/ProductWarranteeSuccessContainer";
import SpecialPointsContainer from "containers/SpecialPointsSystem/SpecialPointsContainer";
import FavouriteFeature from "containers/SpecialPointsSystem/SpecialPointsContainer/Features/FavouriteFeature/FavouriteContainer";
import QuickTestContainer from "containers/SpecialPointsSystem/SpecialPointsContainer/Features/FavouriteFeature/QuickTestContainer";
import QuickTestSuccessContainer from "containers/SpecialPointsSystem/SpecialPointsContainer/Features/FavouriteFeature/QuickTestSuccessContainer";
import GameFeature from "containers/SpecialPointsSystem/SpecialPointsContainer/Features/GameFeature/GameContainer";
import PointRedeemContainer from "containers/PointRedeemSystem/PointRedeemContainer";
import EcouponContainer from "containers/PointRedeemSystem/PointRedeemContainer/Tabs/EcouponContainer";
import GiftContainer from "containers/PointRedeemSystem/PointRedeemContainer/Tabs/GiftContainer";
import VoucherContainer from "containers/PointRedeemSystem/PointRedeemContainer/Tabs/VoucherContainer";
import PointRedeemDetailContainer from "containers/PointRedeemSystem/PointRedeemContainer/Steps/PointRedeemDetailContainer";
import PointRedeemQRCodeContainer from "containers/PointRedeemSystem/PointRedeemContainer/Steps/PointRedeemQRCodeContainer";
import PointRedeemShippingContainer from "containers/PointRedeemSystem/PointRedeemContainer/Steps/PointRedeemShippingContainer";
import HistoryContainer from "containers/HistorySystem/HistoryContainer";
import NewContainer from "containers/NewSystem/NewContainer";
import NewDetailContainer from "containers/NewSystem/NewDetailContainer";
import ProfileContainer from "containers/ProfileSystem/ProfileContainer";
import ActivityHistoryContainer from "containers/ProfileSystem/ProfileContainer/Tabs/ActivityHistoryContainer";
import ProfileTContainer from "containers/ProfileSystem/ProfileContainer/Tabs/ProfileTContainer";
import RedeemHistoryContainer from "containers/ProfileSystem/ProfileContainer/Tabs/RedeemHistoryContainer";
import BookingContainer from "containers/BookingSystem/BookingContainer";
import BookingDetailContainer from "containers/BookingSystem/BookingDetailContainer";

const systemRoutes = [
  {
    key: "home",
    name: "Home",
    path: ROUTE_PATH.HOME,
    element: (
      <HomeContainer
        data={[
          {
            key: "activities",
            name: "Activities",
            name_th: "กิจกรรม",
            icon: <Icons.SubMenuActivity />,
            path: ROUTE_PATH.ACTIVITIES,
            element: <ActivitiesContainer />,
          },
          {
            key: "product_warrantee",
            name: "Product Warranty",
            name_th: "รับประกันสินค้า",
            icon: <Icons.SubMenuProductWarrantee />,
            path: ROUTE_PATH.PRODUCT_WARRANTEE,
            element: <ProductWarranteeContainer />,
          },
          {
            key: "special_points",
            name: "Special Points",
            name_th: "คะแนนพิเศษ",
            icon: <Icons.SubMenuSpecialPoint />,
            path: ROUTE_PATH.SPECIAL_POINTS,
            element: (
              <SpecialPointsContainer
                data={[
                  {
                    key: "favourite",
                    name: "Favourite",
                    name_th: "ดนตรีประเภทที่คุณชอบและสนใจ",
                    icon: <Icons.SubMenuFav />,
                    path: ROUTE_PATH.SPECIAL_POINTS_FAV,
                    element: <FavouriteFeature />,
                  },
                  {
                    key: "game",
                    name: "Game",
                    name_th: "Lucky draw",
                    icon: <Icons.SubMenuGame />,
                    path: ROUTE_PATH.SPECIAL_POINTS_GAME,
                    element: <GameFeature />,
                  },
                ]}
              />
            ),
          },
        ]}
      />
    ),
  },
  {
    key: "activities",
    name: "Activities",
    path: ROUTE_PATH.ACTIVITIES,
    element: <ActivitiesContainer />,
  },
  {
    key: "activities",
    name: "Activities",
    path: ROUTE_PATH.ACTIVITIES_DETAIL,
    element: <ActivitiesDetailContainer />,
  },
  {
    key: "activities_form",
    name: "Activities Form",
    path: ROUTE_PATH.ACTIVITY_FORM_DETAIL,
    element: <ActivitiesFormDetailContainer />,
  },
  {
    key: "activities_form",
    name: "Activities Form",
    path: ROUTE_PATH.ACTIVITY_FORM_QUIZ,
    element: <ActivityFormQuizContainer />,
  },
  {
    key: "product_warrantee",
    name: "Product Warranty",
    path: ROUTE_PATH.PRODUCT_WARRANTEE,
    element: <ProductWarranteeContainer />,
  },
  {
    key: "product_warrantee",
    name: "Product Warranty",
    path: ROUTE_PATH.PRODUCT_WARRANTEE_DETAIL,
    element: <ProductWarranteeDetailContainer />,
  },
  {
    key: "product_warrantee",
    name: "Product Warranty",
    path: ROUTE_PATH.PRODUCT_WARRANTEE_SUCCESS_DETAIL,
    element: <ProductWarranteeSuccessContainer />,
  },
  {
    key: "special_points",
    name: "Special Points",
    path: ROUTE_PATH.SPECIAL_POINTS,
    element: (
      <SpecialPointsContainer
        data={[
          {
            key: "favourite",
            name: "ดนตรีประเภทที่คุณชอบ",
            name_th: "และสนใจ",
            icon: <Icons.SubMenuFav />,
            path: ROUTE_PATH.SPECIAL_POINTS_FAV,
            element: <FavouriteFeature />,
          },
          {
            key: "game",
            name: "Game",
            name_th: "Lucky draw",
            icon: <Icons.SubMenuGame />,
            path: ROUTE_PATH.SPECIAL_POINTS_GAME,
            element: <GameFeature />,
          },
        ]}
      />
    ),
  },
  {
    key: "special",
    name: "Special Points",
    path: ROUTE_PATH.SPECIAL_POINTS_FAV_QUICK_DETAIL,
    element: <QuickTestContainer />,
  },
  {
    key: "special",
    name: "Special Points",
    path: ROUTE_PATH.SPECIAL_POINTS_FAV_QUICK_SUCCESS_DETAIL,
    element: <QuickTestSuccessContainer />,
  },
  {
    key: "favourite",
    name: "Favourite",
    path: ROUTE_PATH.SPECIAL_POINTS_FAV,
    element: <FavouriteFeature />,
  },
  {
    key: "game",
    name: "Game",
    path: ROUTE_PATH.SPECIAL_POINTS_GAME,
    element: <GameFeature />,
  },
  {
    key: "point_redeem",
    name: "Point Redeem",
    path: ROUTE_PATH.POINT_REDEEM,
    element: (
      <PointRedeemContainer
        data={[
          {
            key: "e_coupon",
            name: "E-Coupon",
            path: ROUTE_PATH.POINT_REDEEM_E_COUPON,
            icon: <Icons.RedeemECoupon />,
            iconAct: <Icons.RedeemECoupon color="#fff" />,
            element: <EcouponContainer />,
          },
          {
            key: "voucher",
            name: "Voucher",
            path: ROUTE_PATH.POINT_REDEEM_VOUCHER,
            icon: <Icons.RedeemVoucher />,
            iconAct: <Icons.RedeemVoucher color="#fff" />,
            element: <VoucherContainer />,
          },
          {
            key: "gift",
            name: "Gift",
            path: ROUTE_PATH.POINT_REDEEM_GIFT,
            icon: <Icons.RedeemGift />,
            iconAct: <Icons.RedeemGift color="#fff" />,
            element: <GiftContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "e_coupon",
        name: "E-Coupon",
        path: ROUTE_PATH.POINT_REDEEM_E_COUPON,
        element: <EcouponContainer />,
      },
      {
        key: "voucher",
        name: "Voucher",
        path: ROUTE_PATH.POINT_REDEEM_VOUCHER,
        element: <VoucherContainer />,
      },
      {
        key: "gift",
        name: "Gift",
        path: ROUTE_PATH.POINT_REDEEM_GIFT,
        element: <GiftContainer />,
      },
      {
        key: "e_coupon",
        name: "E-Coupon",
        path: ROUTE_PATH.POINT_REDEEM_DETAIL,
        element: <PointRedeemDetailContainer />,
      },
      {
        key: "e_coupon",
        name: "E-Coupon",
        path: ROUTE_PATH.POINT_REDEEM_QRCODE_DETAIL,
        element: <PointRedeemQRCodeContainer />,
      },
      {
        key: "e_coupon",
        name: "E-Coupon",
        path: ROUTE_PATH.POINT_REDEEM_SHIPPING_DETAIL,
        element: <PointRedeemShippingContainer />,
      },
    ],
  },

  {
    key: "history",
    name: "History",
    path: ROUTE_PATH.HISTORY,
    element: <HistoryContainer />,
  },
  {
    key: "new",
    name: "News",
    path: ROUTE_PATH.NEW,
    element: <NewContainer />,
  },
  {
    key: "new",
    name: "News",
    path: ROUTE_PATH.NEW_DETAIL,
    element: <NewDetailContainer />,
  },
  {
    key: "profile",
    name: "Profile",
    path: ROUTE_PATH.PROFILE,
    element: (
      <ProfileContainer
        data={[
          {
            key: "profile_me",
            name: "Profile",
            path: ROUTE_PATH.PROFILE_ME,
            element: <ProfileTContainer />,
          },
          {
            key: "activity_history",
            name: ["Activity of", "History"],
            path: ROUTE_PATH.PROFILE_ACTIVITY_HISTORY,
            element: <ActivityHistoryContainer />,
          },
          {
            key: "redeem_history",
            name: ["Redemption", "Historyactivity"],
            path: ROUTE_PATH.PROFILE_REDEEEM_HISTORY,
            element: <RedeemHistoryContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: "profile",
        name: "profile_me",
        path: ROUTE_PATH.PROFILE_ME,
        element: <ProfileTContainer />,
      },
      {
        key: "activity_history",
        name: "Activity History",
        path: ROUTE_PATH.PROFILE_ACTIVITY_HISTORY,
        element: <ActivityHistoryContainer />,
      },
      {
        key: "redeem_history",
        name: "Redeem History",
        path: ROUTE_PATH.PROFILE_REDEEEM_HISTORY,
        element: <RedeemHistoryContainer />,
      },
    ],
  },
  //
  {
    key: "booking",
    name: "Booking",
    path: ROUTE_PATH.BOOKING,
    element: <BookingContainer />,
  },
  {
    key: "booking-detail",
    name: "BookingDetail",
    path: ROUTE_PATH.BOOKING_DETAIL,
    element: <BookingDetailContainer />,
  },
];

export default systemRoutes;
