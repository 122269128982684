import React, { useState } from "react";
import { VisitorFollowerFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const VisitorFollowerForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [rows, setRows] = useState(0);
  const [formData, setFormData] = useState(
    Array(0).fill({ name: "", surname: "", birthDate: "" })
  );

  // const handleInputChange = (index, field, value) => {
  //   const updatedFormData = [...formData];
  //   updatedFormData[index][field] = value;
  //   setFormData(updatedFormData);
  // };

  const handleAddRows = (e) => {
    const newRows = parseInt(e.target.value);
    setRows(newRows);
    setFormData(Array(newRows).fill({ name: "", surname: "", birthDate: "" }));
  };

  return (
    <VisitorFollowerFormStyled>
      <div className="menu">
        <div className="title">เพิ่มผู้ติดตามที่เป็นเด็กจำนวน: </div>
        <div className="input_wrap dropdown">
          <Controller
            control={control}
            name="rows"
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                theme_standard
                options={[0, 1, 2, 3, 4, 5].map((num) => ({
                  label: `${num} `,
                  value: num,
                }))}
                placeholder={"0"}
                errors={errors.rows?.message}
                onChange={(selected) => {
                  const value = selected?.value ?? 0;
                  handleAddRows({ target: { value } });
                }}
              />
            )}
          />
        </div>
        <span className="title"> คน</span>
      </div>
      <form onSubmit={handleSubmit}>
        {formData.map((data, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <h4>คนที่ {index + 1}</h4>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="ชื่อ"
                    errors={errors.personal_first_name?.message}
                  />
                )}
                name="personal_first_name"
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="นามสกุล"
                    errors={errors.personal_last_name?.message}
                  />
                )}
                name="personal_last_name"
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder="วันเกิด"
                    errors={errors.personal_last_name?.message}
                  />
                )}
                name="personal_last_name"
                defaultValue=""
              />
            </div>
            {/* <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    isShowMonthDropdown
                    isShowYearDropdown
                    maxDate={new Date()}
                    placeholderText="วันเกิด"
                    errors={errors.birthday?.message}
                  />
                )}
                name="birthday"
                defaultValue=""
              />
            </div> */}
          </div>
        ))}
        {rows > 0 && <Buttons.BgStandard type="submit" label={"submit"} />}
      </form>
    </VisitorFollowerFormStyled>
  );
};

VisitorFollowerForm.propTypes = {};

export default VisitorFollowerForm;
