import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DropdownSelectStyled } from "./styled";
import Select from "react-select";
import theme from "styles/theme.json";
import { Icons } from "components";
import { components } from "react-select";

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.DropdownArrow />
    </components.DropdownIndicator>
  );
};

const DropdownSelect = ({
  theme_standard,
  label,
  options,
  value,
  onChange,
  onChaneCustom,
  placeholder,
  errors,
  disabled,
  isSplitLabel,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      color: state.isSelected ? theme.COLORS.PURPLE_1 : theme.COLORS.PURPLE_1,
      padding: "8px 10px",
      background: state.isFocused ? "#f2f9fc" : theme.COLORS.WHITE_1,
      fontSize: theme.FONT.SIZE.S14,
    }),
    indicatorSeparator: () => ({
      display: "none", // ซ่อนเส้น Separator (เส้นระหว่าง dropdown กับ text)
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      borderRadius: 9,
      border: `1.5px solid ${theme.COLORS.PURPLE_1}`,
      padding: "1px 2px 1px 2px",
      width: "calc(100% - 6px)",
      display: "flex",
      background: theme.COLORS.WHITE_1,
      fontSize: theme.FONT.SIZE.S14,
      fontFamily: theme.FONT.STYLE.PROMPT_REGULAR,
      color: theme.COLORS.PURPLE_1,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.COLORS.PURPLE_1,
    }),
    singleValue: (provided, state) => {
      const transition = "opacity 300ms";
      return {
        ...provided,
        transition,
        color: theme.COLORS.PURPLE_1,
      };
    },
  };

  return (
    <DropdownSelectStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <Select
          styles={customStyles}
          isDisabled={disabled}
          options={options}
          onChange={(e) => {
            onChange({
              ...e,
              label: isSplitLabel ? e.label.split(" ")[0] : e.label,
              value: e.value,
            });
            onChaneCustom && onChaneCustom(e);
          }}
          value={value}
          placeholder={placeholder}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
          }}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DropdownSelectStyled>
  );
};

DropdownSelect.propTypes = {};

export default DropdownSelect;
