import styled from "styled-components";

export const BookingStep2FormStyled = styled.div`
  .section {
    .f_title {
      margin-left: 30px;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
    .input_wrap {
      margin-bottom: 20px;
      &.mb_70 {
        margin-bottom: 70px;
      }
      &.ml_50 {
        margin-left: 50px;
      }
    }
    .btn_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        width: 223px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
