import styled from "styled-components";

export const VisitorFollowerFormStyled = styled.div`
  color: ${({ theme }) => theme.COLORS.PURPLE_1};
  .menu {
    padding: 20px 0;
    display: flex;
    height: 50px;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    .title {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    }
    .input_wrap {
      &.dropdown {
        width: fit-content;
      }
    }
  }
  .input_wrap {
    margin-bottom: 10px;
  }
`;
