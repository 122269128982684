import styled from 'styled-components';

export const BookingInfoConfirmStyled = styled.div`
  .activity_confirm_container {
    padding: 1rem;
    width: 290px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .avc_title {
      margin-bottom: 0.75rem;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .avc_body {
      margin-bottom: 1rem;
      .avc_row {
        margin-bottom: 0.05rem;
        display: flex;
        .cr_left_col {
          flex-basis: 45%;
        }
        .cr_right_col {
          flex: 1;
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
        }
      }
    }
    .avc_action_row {
      display: flex;
      column-gap: 1rem;
      .ca_col {
        flex: 1;
      }
    }
  }

  .theme_standard {
  }
`;
