import { useEffect, useState } from "react";
import { MenuBarWidgetStyled } from "./styled";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import theme from "styles/theme.json";
import { useTranslation } from "react-i18next";
import MenuProfile from "components/Icons/MenuProfile";
import MenuNews from "components/Icons/MenuNews";
import MenuHome from "components/Icons/MenuHome";
import MenuPointRedeem from "components/Icons/MenuPointRedeem";
import MenuHistory from "components/Icons/MenuHistory";

const MenuBarWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation(["menu"]);

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split("/");
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.routePath);
  };
  return (
    <MenuBarWidgetStyled>
      {menuList &&
        menuList.map((e, i) => (
          <div
            key={i}
            className={`menu_wrap ${_active === e.parentMenu ? "active" : ""}`}
            onClick={() => _handleClickMenu(e)}
          >
            <div className="menu_item">
              <div className="mi_icon_wrap">
                {_active === e.parentMenu ? e.iconAct : e.icon}
              </div>
              {typeof e.label === "string" ? (
                <div className="mi_label">{t(e.label, { ns: "menu" })}</div>
              ) : (
                <div className="muti_label_block">
                  {e.label.map((f, j) => (
                    <div key={j} className="mi_label">
                      {f}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
    </MenuBarWidgetStyled>
  );
};

const menuList = [
  {
    parentMenu: "profile",
    label: "PROFILE",
    icon: <MenuProfile />,
    iconAct: <MenuProfile color={theme.COLORS.WHITE_1} />,
    routePath: ROUTE_PATH.PROFILE_ME,
  },
  {
    parentMenu: "new",
    label: "NEWS",
    icon: <MenuNews />,
    iconAct: <MenuNews color={theme.COLORS.WHITE_1} />,
    routePath: ROUTE_PATH.NEW,
  },
  {
    parentMenu: "home",
    label: "HOME",
    icon: <MenuHome />,
    iconAct: <MenuHome color={theme.COLORS.WHITE_1} />,
    routePath: ROUTE_PATH.HOME,
  },
  {
    parentMenu: "point-redeem",
    label: "POINT",
    isMultiline: true,
    icon: <MenuPointRedeem />,
    iconAct: <MenuPointRedeem color={theme.COLORS.WHITE_1} />,
    routePath: ROUTE_PATH.POINT_REDEEM_E_COUPON,
  },
  {
    parentMenu: "history",
    label: "HISTORY",
    icon: <MenuHistory />,
    iconAct: <MenuHistory color={theme.COLORS.WHITE_1} />,
    routePath: ROUTE_PATH.HISTORY,
  },
];

export default MenuBarWidget;
