import styled from "styled-components";

export const BookingStep1FormStyled = styled.div`
  .section {
    .header {
      .title {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
      .sub_title {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        margin-bottom: 20px;
      }
    }
    .title {
      margin-left: 30px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
    .input_wrap {
      margin-bottom: 20px;
      &.mb_70 {
        margin-bottom: 70px;
      }
      &.ml_50 {
        margin-left: 50px;
      }
    }
    .btn_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        width: 223px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .title {
      margin-bottom: 20px;
    }
    .schedule {
      .top_sec {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .top_left {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        }
        .top_right {
          background: none;
          border: none;
          cursor: pointer;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        }
      }
      .table {
        display: flex;
        column-gap: 5px;
        .table_left_column {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .time_cell {
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
            height: 42px;
            background: #b783eb;
            font-size: 12px;
            white-space: pre-wrap;
            color: #ffffff;
            &.f_16 {
              font-size: 16px;
            }
          }
        }
        .table_right_column {
          .day_row {
            display: flex;
            column-gap: 5px;
            margin-bottom: 5px;
            .day_cell {
              padding: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 42px;
              height: 42px;
              background: ${({ theme }) => theme.COLORS.PURPLE_1};
              color: ${({ theme }) => theme.COLORS.WHITE_1};
              white-space: pre-wrap;
              text-align: center;
            }
          }
          .data_cell_block {
            display: flex;
            flex-wrap: wrap;
            row-gap: 5px;
            column-gap: 5px;
            margin-bottom: 20px;
            .data_cell {
              padding: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 42px;
              height: 42px;
              background: #707070;
              color: #ffffff;
              white-space: pre-wrap;
              text-align: center;
            }
          }
          .bottom {
            display: flex;
            margin-bottom: 30px;
            .status_detail {
              height: 15px;
              display: flex;
              margin-right: 20px;
              align-items: center;
              .box {
                width: 26px;
                height: 15px;
                margin-right: 10px;
                &.full {
                  background: #ff9c96;
                }
                &.almost {
                  background: #efc788;
                }
                &.available {
                  background: #a694b8;
                }
              }
              .status_title {
                color: ${({ theme }) => theme.COLORS.PURPLE_1};
                font-size: ${({ theme }) => theme.FONT.SIZE.S12};
              }
            }
          }
        }
      }
    }
  }
`;
