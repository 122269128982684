import React from "react";

export default function renderIcon({
  width = 21,
  height = 21,
  color = "#9ca2b8",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
    >
      <path
        id="Polygon_210"
        data-name="Polygon 210"
        d="M10.5,0,21,17H0Z"
        transform="translate(21 17) rotate(180)"
        fill="#4b1e78"
      />
    </svg>
  );
}
